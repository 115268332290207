.rbc-calendar {
  width: 80vw;
}

.rbc-time-header {
  flex-direction: row !important;
  justify-content: space-between !important;
}

.rbc-header .rbc-today {
  display: none;
}

.rbc-time-header-content {
  width: 150px;
  min-width: 150px;
  overflow-x: hidden;
}

.rbc-day-slot {
  min-width: 150px !important;
}